div#reportTopBar {
    display: flex;
    background: white;
    box-shadow: 0px 2px 5px #3535493b;
    height: 50px;
    align-items: center;
    padding: 0px 15px;
    position: fixed;
    width: 100%;
    z-index: 9;
    justify-content: space-between;
}

.reports {
    height: 100%;
}

.report-leftbar {
    position: fixed;
    z-index: 99999999;
    top: 0;
}

.mapTabTitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
}

.mapTabTitle small {
    font-size: 12px;
}

.reportSideBarWrapper {
    padding: 0px 8px;
    height: 100%;
    overflow-y: auto;
}

nav.menu>div {
    padding-left: 0;
}

.reportPageWrapper {
    margin-left: 60px;
    padding: 5px 15px;
    padding-top: 50px;
}

.charts {
    display: flex;
    flex-wrap: wrap;
}

.backdrop {
    z-index: 9999999999 !important;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card {
    width: 18%;
    padding: 0px 2px;
    margin-bottom: 30px;
}

.reports-card>div {
    padding: 10px !important;
    display: flex;
    flex-direction: column;
}

.reportcard-header {
    display: flex;
    align-items: center;
    padding: 10px;
}

p.viewmore-link {
    color: #6b6bde;
    cursor: pointer;
    text-align: center;
    margin: 10px 0px;
    font-size: 14px;
    margin-bottom: 0;
}

.reportcard-icon {
    width: 30%;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 1px 2px 5px #c6c6c6;
}

.reportcard-icon.total {
    background: #6b6bde;
}

.reportPopupWrapper.all h2 {
    color: #6b6bde;
}

.reportcard-icon.running {
    background: green;
}

.reportPopupWrapper.running h2 {
    color: green;
}

.reportcard-icon.idle {
    background: darkorange;
}

.reportPopupWrapper.idle h2 {
    color: darkorange;
}

.reportcard-icon.stopped {
    background: red;
}

.reportPopupWrapper.stopped h2 {
    color: red;
}

.reportcard-icon.nosignal {
    background: #c6c6c6;
}

.reportPopupWrapper.nosignal h2 {
    color: #868686;
}

.reportcard-icon.available {
    background: darkviolet;
}

.reportPopupWrapper.available h2 {
    color: darkviolet;
}

.reportcard-icon.busy {
    background: red;
}

.reportPopupWrapper.busy h2 {
    color: red;
}

div#reportFooter {
    text-align: center;
    font-size: 12px;
    padding: 5px 0px;
    background: #ededed;
}

div#reportFooter p {
    margin: 4px 0px;
    margin-top: 6px;
}




.reportcard-icon svg {
    color: #fff;
}

.reportcard-details {
    width: 70%;
    padding: 0px 5px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reportcard-title {
    margin-top: 10px;
    margin-bottom: 0;
    color: gray;
    font-size: 14px;
    font-weight: normal;
}

.reportcard-desc {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: 5px;
}

nav.breadcrumb {
    padding-left: 0px;
}

.whiteLoader svg {
    color: #fff;
}

.reportPopup-wrapper {
    display: flex;
    justify-content: center;
    min-height: 300px;
    align-items: center;
    flex-direction: column;
}

p.reportDescription {
    margin-bottom: 0;
    margin-top: -10px;
    font-size: 12px;
    color: #0000008a;
}

.reportFilters {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;
}

.width10 {
    width: 10%;
}

.width15 {
    width: 15%;
}

.width20 {
    width: 20%;
}

.width25 {
    width: 25%;
}

.width30 {
    width: 30%;
}

.width33 {
    width: 33%;
}

.width50 {
    width: 50%;
}

.width100 {
    width: 100%;
}

.height100 {
    height: 100%;
}

.padLeft-10 {
    padding-left: 10px;
}

.primaryBtn {
    background: #800000 !important;
    color: #fff !important;
    padding: 4px 15px !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.primaryBtn:hover {
    background: #800000 !important;
}

.reportPageWrapper .uidatepicker label {
    display: none;
}

.reportPageWrapper .uidatepicker {
    padding: 0px 15px;
    margin-bottom: 0;
}

.reportFilters .uidatepicker .MuiInput-root {
    margin-top: 0;
}

.dataTables_scrollHead th {
    font-size: 12px;
    color: #fff;
    text-align: left;
}

.dataTables_scrollBody {
    font-size: 12px;
}

.autoCompleteDropdown input {
    font-size: 12px;
    font-weight: 500;
    top: 3px;
    position: relative;
}

label.MuiInputLabel-root[data-shrink="false"] {
    font-size: 12px;
    font-weight: 500;
}

.autocomplete-box>div>div {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}

.autocompletePopper {
    border: 1px solid #c6c6c6;
    border-top: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

div#reportGrid_info {
    font-size: 12px;
}

.reportSideBarWrapper .MuiListItemText-root {
    padding-left: 0px;
}

.reportSideBarWrapper .menuicon {
    justify-content: center;
    min-width: 40px;
}

.subtitle {
    font-size: 16px;
    margin: 5px 0px;
    color: #800000;
    font-weight: 500;
}

.reportSideBarWrapper>nav>div.MuiListSubheader-root {
    color: #800000;
    font-size: 16px;
    font-weight: 500;
}

.report-leftbar .leftdrawer-menu {
    width: 300px;
    box-shadow: 2px 0px 50px #5f5f5f;
}

.menuicon svg {
    font-size: 20px;
    color: #800000;
}

.report-menu {
    padding: 4px 0px !important;
    margin-bottom: 15px !important;
}

.report-menu.active span {
    font-weight: 500;
}

.report-menu span {
    font-size: 14px;
    color: #000;
    padding-top: 2px;
}

.leftdrawer-menu {
    box-shadow: 0px 1px 10px #c4c4c4;
}

.dialogBox.reportPopupWrapper {
    width: 100%;
}

.reportPopup-wrapper>div {
    width: 100%;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.primaryLoader {
    color: #800000 !important;
}

table.dataTable.no-footer {
    margin-top: 0px !important;
    padding: 4px 0px;
}

.dataTables_scrollHead {
    background: #7e7e7e;
}

.dataTables_scrollHead th:before,
.dataTables_scrollHead th:after {
    top: 0px;
}

table#reportGrid tbody td {
    border: 1px solid #a9a9a9;
    border-top: 0;
    border-right: 0;
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 14px;
}

.reportPopupWrapper .dialogContent {
    padding-top: 0px !important;
}

.uidatepicker button.MuiButtonBase-root {
    padding: 8px;
}

.dt-buttons {
    margin-bottom: -20px;
}

div#topLeftBox {
    padding-left: 60px;
}

div#topRightBox {
    padding-right: 25px;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 15px;
    padding-top: 4px;
}

div#topRightBox>button {
    padding: 8px;
}

div#topRightBox>button svg {
    color: #404040;
    font-size: 18px;
}

div#reportUserInfo h4 {
    margin-bottom: 0;
    margin-top: -2px;
    font-size: 14px;
    color: #676767;
    font-weight: 500;
}

div#reportUserInfo p {
    margin-bottom: 0;
    margin-top: 0px;
    font-size: 12px;
    color: #a5a5a5;
    text-transform: capitalize;
}

div#reportLogo img {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
}

div#reportUserInfo {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
}

div#reportGrid_paginate ul {
    display: flex;
    list-style: none;
    margin-top: -15px;
}

.reportGridWrapper {
    overflow: auto;
}

div#reportGrid_paginate ul>li {
    padding: 0px 5px;
    font-size: 12px;
}

div#reportGrid_paginate ul>li a {
    text-decoration: none;
}

div#reportGrid_paginate ul>li.disabled a {
    color: #9d9d9d !important;
}

table#reportGrid.paginated {
    font-size: 12px;
}

table#reportGrid.paginated thead th {
    border: 1px solid #c0c0c0;
    border-right: none;
}

table#reportGrid.paginated thead th:last-child {
    border-right: 1px solid #c0c0c0;
}

table#reportGrid tbody td:last-child {
    border-right: 1px solid #c0c0c0;
}

div#reportGrid_paginate ul>li.active {
    font-weight: 500;
}