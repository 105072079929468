.leaflet-container {
    background: #fff;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
    position: fixed !important;
}

.leaflet-tooltip {
    padding: 0px 2px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.leaflet-tooltip {
    text-transform: none;
}

div#animationBtnWrapper {
    position: absolute;
    right: 9px;
    bottom: 70px;
    z-index: 999999;
}

div#animationBtnWrapper button svg {
    color: #8f8f8f;
    font-size: 18px;
}

div#animationBtnWrapper button.active svg {
    color: #000;
}

div#animationBtnWrapper button {
    padding: 4px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 1px 2px 5px #b4b4b4;
}

.movingMarker img {
    filter: drop-shadow(0px 0px 5px #000);
}

.mapContainer.mapLabels .leaflet-tooltip.datapoint-title span {
    display: block;
}

.mapContainer.mapLabels .leaflet-tooltip.datapoint-title:before {
    display: block;
}


.mapContainer.mapLabels .leaflet-tooltip span {
    display: none;
}

.mapContainer.mapLabels .leaflet-tooltip:before {
    display: none;
}

img.leaflet-marker-icon {
    filter: drop-shadow(0px 1px 4px #000);
}

img.datapoint.leaflet-marker-icon {
    filter: drop-shadow(0px 1px 4px #00000014);
}

.leaflet-control-attribution.leaflet-control,
.leaflet-top.leaflet-left {
    display: none;
}

.mapContainer.fullscreen .leaflet-control-scale.leaflet-control {
    top: 0;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
    background-color: #fff;
    width: 24px;
    height: 48px;
    border: none;
    overflow: hidden;
    box-shadow: 0px 0px 8px #b1b1b1;
}

a.leaflet-control-zoom-in,
a.leaflet-control-zoom-out {
    width: auto !important;
    height: 24px !important;
    line-height: 24px !important;
    font-size: 14px !important;
    border: none;
    color: #000 !important;
}

.leaflet-control-scale.leaflet-control {
    position: absolute;
    top: 65px;
    right: 0;
}

.leaflet-marker-icon>div {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
}

.markerClusterSmall {
    background-color: #800000;
    border: 2px solid #fff;
    opacity: 0.8 !important;
    box-shadow: 0px 1px 6px #676767;
    padding: 5px;
    border-radius: 50%;
}

.markerClusterMedium {
    background-color: #800000;
    border: 2px solid #fff;
    opacity: 0.8 !important;
    box-shadow: 0px 1px 6px #676767;
    padding: 6px;
    border-radius: 50%;
    width: 12px !important;
    height: 11px !important;
}

.markerClusterLarge {
    border: 2px solid #fff;
    opacity: 0.8 !important;
    box-shadow: 0px 1px 6px #676767;
    padding: 5px;
    border-radius: 50%;
    background-color: #800000;
    width: 16px !important;
    height: 15px !important;
}

.markerClusterLargeXL {
    border: 2px solid #fff;
    opacity: 0.8 !important;
    box-shadow: 0px 1px 6px #676767;
    padding: 5px;
    border-radius: 50%;
    background-color: #800000;
    width: 16px !important;
    height: 15px !important;
}

.markerClusterSmall>div {
    left: 8px;
}

.markerClusterMedium>div {
    left: 5px;
}

.markerClusterLarge>div {
    left: 3px;
}

.markerClusterLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.leaflet-fade-anim .leaflet-popup {
    transition: all 0ms !important;
}

.leaflet-popup-content {
    margin: 0px;
}

.leaflet-popup-content-wrapper {
    border-radius: 0;
}

.leaflet-popup-tip {
    width: 10px;
    height: 10px;
}

.markerPopup {
    margin: 5px;
}

.markerPopup h4 {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 3px;
}

span.popupName {
    margin-right: 5px;
}

span.popupStatus {
    padding: 2px 8px;
    font-size: 10px;
    border-radius: 10px;
    color: #fff;
    letter-spacing: 0.5px;
    position: relative;
    top: -2px;
}

span.popupStatus.running {
    background-color: #61A262;
}

span.popupStatus.nosignal {
    background-color: #C4C4C4;
}

span.popupStatus.idle {
    background-color: orange;
}

span.popupStatus.stopped {
    background-color: red;
}

.popupAddress {
    color: #6b6b6b;
    margin: 0px !important;
    text-align: justify;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 4px !important;
    text-transform: capitalize;
}

.markerPopup td {
    font-size: 12px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.markerPopup a {
    text-decoration: none;
}

ul.popupShowRoute {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 4px;
}

ul.popupOptions>li {
    cursor: pointer;
}

input#showRoute {
    padding: 3px 5px;
    font-size: 12px;
}

.showRoute {
    font-size: 12px !important;
    border: 1px solid #800000 !important;
    padding: 0px 5px !important;
    color: #800000 !important;
    background-color: #fff !important;
    text-transform: initial !important;
}

ul.popupOptions {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-left: -5px;
}

ul.popupOptions svg {
    font-size: 18px;
    color: #000;
}

ul.popupOptions button {
    padding: 8px;
    margin-right: 2px;
}

ul.popupOptions>li>span {
    position: relative;
    font-size: 12px;
    top: 2px;
    margin-right: 10px;
}

ul.popupOptions li:first-child svg {
    font-size: 16px;
}

.mapVehicles img.mapMarker {
    display: none;
}

.leaflet-tooltip.zoneLabel.hide {
    display: none;
}

.leaflet-tooltip.pointLabel.hide {
    display: none;
}

div#addressAutoComplete {
    position: absolute;
    right: 4px;
    top: 50px;
    z-index: 99 !important;
    min-width: 250px;
}

.autocomplete-dropdown-container {
    max-width: 250px;
    max-height: 300px;
    overflow-y: auto;
    padding: 0px 5px;
    background: #fff;
}

img.leaflet-marker-icon.incidentMarker {
    filter: drop-shadow(0px 0px 2px #fff);
}

img.leaflet-marker-icon.trackMarker {
    z-index: 999999 !important;
}

div#addressAutoComplete input {
    width: 96%;
    padding: 2px;
}

.suggestion-item,
.suggestion-item--active {
    font-size: 12px;
    padding: 5px 0px;
    border-bottom: 1px solid #e5e5e5;
}

.suggestion-item--active {
    font-weight: 500;
}