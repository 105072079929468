.fleetGrid {
    margin-top: 10px;
}

.fleetGrid thead>tr,
.fleetGrid tbody>tr {
    height: auto;
}

.fleetGrid thead>tr>th {
    padding: 2px 5px !important;
    background-color: #ececec;
}

.fleetGrid tbody>tr>td {
    padding: 2px 5px !important;
}