.callBtn {
    background: green !important;
    color: #fff !important;
    min-width: 70px;
    border-radius: 4px !important;
}

.callBtn h4 {
    margin: 0;
    font-size: 12px;
}

.userOptions {
    padding: 5px;
}