.mapTabs {
    background-color: transparent;
    min-height: auto !important;
}

.mapTabWrapper {
    padding: 0px 8px;
    height: 100%;
    overflow-y: hidden;
}

.maptab {
    border-radius: 50px !important;
    min-width: 100px !important;
    min-height: 25px !important;
    font-size: 12px !important;
    color: #6e6e70 !important;
}

.mapTabWrapper>.mapTabTitle {
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 400;
    align-items: center;
    display: flex;
}

.mapTabContainer {
    box-shadow: none !important;
    margin-top: 0px;
    height: calc(100% - 55px);
}

button.activeTab {
    background-color: #800000;
    color: #fff !important;
}

span.fleettabindicator {
    display: none;
}

button.activeTab span.mapTabLabel {
    color: #fff;
}

span.mapTabLabel {
    color: #434343;
}

#vehicleSearchBox {
    position: relative;
}

.tabContent {
    position: relative;
    height: 100%;
}


div#vehicleFilterBox,
div#vehicleSortBox {
    margin-top: 10px;
    position: absolute;
    z-index: 9999999;
    border-radius: 5px;
    background-color: #fff;
    display: none;
    box-shadow: 0px 1px 8px #b9b9b9;
    padding: 8px;
}

div#vehicleSortBox {
    right: 0px;
    padding: 5px 0px;
    padding-right: 5px;
}



div#vehicleFilterBox>ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
}

img.filterIcon,
img.sortIcon {
    margin-left: 3px;
}

div#vehicleFilterBox button {
    font-size: 10px;
}

ul.vehicleFilters Button {
    font-size: 10px;
}

ul.vehicleFilters Button.active {
    color: #800000;
    background-color: #00000012;
}

.vlistAutosizer {
    height: auto !important;
}

.filterBox {
    max-height: 200px;
    background-color: white;
    width: 240px;
    overflow-y: auto;
    z-index: 99999;
    transition: all 0.3s;
    margin-bottom: 10px;
    overflow-y: hidden !important;
}

.filterBox:last-child {
    margin-bottom: 0px;
}

div#vehicleFilterBox.active,
div#vehicleSortBox.active {
    opacity: 1;
    display: block;
}

div#vehicleFilterBox.active::before,
div#vehicleSortBox.active::before {
    content: '';
    position: absolute;
    left: 16px;
    top: -10px;
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    border-color: #4b1b9a00 #06060600 #ffffff #f1060600;
    filter: drop-shadow(0px -3px 2px #0000000e);
}

div#vehicleSortBox.active::before {
    left: auto;
    right: 16px;
}

div#vehicleFilterBox .SearchInput {
    font-size: 10px !important;
    padding: 0px 5px !important;
    width: 100%;
}


div#vehicleFilterBox .expansionPanelDetails button {
    padding: 0px 2px !important;
    top: 12px;
}

div#vehicleFilterBox .expansionPanelSummary .toggleIcon {
    width: 15px;
}

ul.vehicleFilters {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 0;
    margin-top: 8px;
    margin-bottom: -27px;
}

.vehicleFilters .checkList {
    height: 150px;
    overflow-y: auto;
}

.sortBy svg {
    color: #800000;
    margin-left: 3px;
    font-size: 18px;
}

.sortBy svg.asc {
    transform: rotateX(180deg);
}

.sortBy svg.desc {
    transform: rotateX(0deg);
}

h4.mapTabTitle small {
    font-size: 10px;
}