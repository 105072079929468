.dialogBox {
    z-index: 99999999 !important;
    right: auto !important;
}

.dialogBox.editVehicleWrapper,
.dialogBox.logoutWrapper,
.dialogBox.profileSettingsWrapper,
.dialogBox.confirmDialog {
    width: 100%;
}

.dialogBody {
    margin: 0px !important;
    padding: 5px 10px;
}

.dialogTitle {
    padding: 0 !important;
    cursor: grabbing;
    margin-bottom: 10px !important;
}

.notDraggable .dialogTitle {
    cursor: inherit;
}

.dialogTitle>h2 {
    font-size: 18px;
    position: relative;
    top: 5px;
    color: #800000;
}

.dialogCloseBtn {
    padding: 5px !important;
    position: absolute !important;
    right: -4px;
    top: -4px;
}

.dialogCloseBtn svg {
    font-size: 16px;
}

.dialogContent {
    padding: 0px !important;
    padding-top: 10px !important;
}

.dialogBtn {
    margin: 5px 0px;
}

.cancelBtn {
    margin-left: 10px !important;
}

.dialogBtn button {
    color: #FFF;
    background-color: #800000;
    font-size: 12px;
    padding: 1px 24px;
    margin-right: -4px;
    border: 1px solid #800000;
    text-transform: initial;
    opacity: 0.8;
    font-weight: bold;
}

.dialogBtn button.cancelBtn {
    background-color: #525252;
    border-color: #525252;
}

.dialogBtn button.cancelBtn:hover {
    opacity: 1;
    background-color: #525252;
}

.dialogBtn button:hover {
    opacity: 1;
    background-color: #800000;
}

div#videoCallPopup .cancelBtn {
    background: red !important;
    border-color: red !important;
}

div#videoCallPopup .dialogBtn button {
    background: green;
    border-color: green;
}