.trackVehicle {
    margin: 10px 0px;
    height: 100%;
}

p.label {
    color: #800000;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
}

.chip {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    background-color: #fff !important;
    border: 1px solid #DFDBDB !important;
    height: auto !important;
    padding: 4px 0px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: 500;
    color: #434343 !important;
}

.chip.active {
    background: #800000 !important;
    color: #fff !important;
    border: 1px solid #800000 !important;
}

.chip:active,
.chip:hover {
    background: #800000 !important;
    color: #fff !important;
    border: 1px solid #800000 !important;
}

.trackBtn {
    position: relative;
    top: -1px;
    left: 8px;
}

.trackBtn>button {
    font-size: 12px;
    border: 1px solid #800000;
    padding: 2px 5px;
    margin-right: 8px;
    text-transform: initial;
    background-color: #fff;
    color: #800000;
}

.trackBtn>button.active {
    border-color: #9F9FA2;
    color: #9F9FA2;
    pointer-events: none;
}

.trackBtn>button:hover {
    background-color: #fff;
    border-color: #800000;
    color: #800000;
}

#stopsDiv .selectDropdown {
    width: 100%;
    min-width: auto !important;
    padding-right: 30px !important;
}

ul[role="listbox"] {
    padding: 0;
}

ul[role="listbox"]>li {
    padding: 2px 5px;
    font-size: 12px;
}

p.label.stopsLabel {
    margin-bottom: -8px;
    margin-top: 5px !important;
}

#trackDateFilter {
    border: 1px solid whitesmoke;
    padding: 0px 5px;
    margin-bottom: 15px;
    font-size: 12px;
}

input#trackVehicles {
    font-size: 12px;
    font-weight: 500;
    top: 3px;
    position: relative;
}

label#trackVehicles-label[data-shrink="false"] {
    font-size: 12px;
    font-weight: 500;
}

#stopsDiv {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}