.checkList {
    width: 100%;
    margin-top: 5px;
}

.checkList svg {
    width: 15px;
    height: 15px;
    color: #800000;
}

.checkList label {
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
}

.checkList label>span {
    padding: 5px;
    margin-top: 2px;
}

.checkList label span {
    font-size: 12px;
}

.checkList label>span:last-child {
    padding: 0px;
    padding-top: 2px;
}