html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
  color: #800000 !important;
}

.primary > svg {
  color: #800000;
}

.leftFixedMenu {
  position: fixed;
  height: 100%;
  margin: 0;
  background-color: #800000;
  width: 60px;
  color: #dfdeec;
  z-index: 9999999;
  font-weight: bold;
}

.leftFixedMenu > ul.topmenu {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  text-align: center;
  margin-top: 40px;
}

.leftFixedMenu > ul.bottommenu {
  position: absolute;
  bottom: 0;
  margin: 0;
  list-style: none;
  padding: 0;
  text-align: center;
  width: 100%;
}

.leftFixedMenu > ul.bottommenu > li {
  margin: 0px;
  color: #9c9c9c;
  cursor: pointer;
  height: 60px;
}

.leftFixedMenu > ul.bottommenu > li > svg {
  margin-top: 18px;
}

.leftFixedMenu > ul.bottommenu > li.active {
  color: #fff;
}

.leftFixedMenu > ul.topmenu > li {
  margin: 0px;
  cursor: pointer;
  color: #9c9c9c;
  height: 60px;
}

.leftFixedMenu > ul.topmenu > li > svg {
  margin-top: 18px;
}

.leftFixedMenu > ul.topmenu > li.active {
  color: #fff;
}

div.leftdrawer {
  position: static !important;
}

.rotateScale {
  transform: rotate(320deg);
}

.leftdrawer-menu {
  left: 60px !important;
  width: 390px;
  padding: 00px;
}

.leftdrawer-menu > div {
  box-shadow: none;
}

.menucolor {
  color: #f1f1f1 !important;
}

.menucolor.active {
  color: #fff !important;
}

.menucolor.active::after {
  content: "";
  position: absolute;
  right: -6px;
  border-style: solid;
  border-width: 8px 8px 8px 0px;
  border-color: transparent #ffffff transparent transparent;
}

.width100 {
  width: 100%;
}

.p0 {
  padding: 0px !important;
}

.pad5 {
  padding: 5px !important;
}

.pB5 {
  padding-bottom: 5px;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mB-8 {
  margin-bottom: -8px !important;
}

.m0 {
  margin: 0px !important;
}

.mT10 {
  margin-top: 10px !important;
}

.menuicon {
  margin-right: 0 !important;
}

.submenu {
  margin-left: 55px !important;
}

.menu {
  overflow: hidden;
}

.tooltip {
  will-change: unset;
}

.placeholder {
  margin: 5px 0px;
  background-color: #eee;
  margin-bottom: 7px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
  height: 55px;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #35354a7a;
  z-index: 999999999;
  top: 0;
}

.loader,
.loaderMsg {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff !important;
}

.loaderMsg {
  left: 25px;
  width: 100%;
  text-align: center;
  top: 58%;
  font-weight: bold;
  text-shadow: 1px 2px 5px #000;
}

.loaderMsg a {
  color: #fff !important;
}

.snackbar-btn {
  font-size: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: #fff !important;
  cursor: pointer;
  background: none;
  box-shadow: none;
  border: none;
}

.divCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.scrollBar::-webkit-scrollbar {
  width: 6px;
}

.scrollBar::-webkit-scrollbar-track {
  background: #ccc;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

.purple {
  color: #800000 !important;
}

.w40p {
  width: 40%;
}

.w50 {
  width: 50%;
}

.no-records-found {
  color: #747474;
  text-align: center;
  height: 25px;
}

div#profileMenu ul {
  display: flex;
  flex-direction: column;
  padding: 0;
}

div#profileMenu {
  position: absolute !important;
  right: 5px;
  top: 37px !important;
  width: 135px !important;
  margin: 0 0 0 auto;
}

div#profileMenu ul > li {
  padding: 4px 15px;
  font-size: 14px;
}

#myProfileBtn button {
  border-radius: 0;
  position: relative;
  top: -1px;
  padding: 4px !important;
}

.bold {
  font-weight: 500;
}

.mB-5 {
  margin-bottom: 5px !important;
}

.mB-10 {
  margin-bottom: 10px !important;
}

.grid {
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switchThumb {
  color: #eaeaea;
}

.switchRoot > span.Mui-checked .switchThumb {
  color: #800000;
}

.switchRoot .switchTrack {
  background-color: #646464 !important;
}

.dropdown {
  width: 100%;
}

.label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #747474;
}

.txt {
  width: 100%;
  margin-bottom: 10px !important;
}

.txt input,
.txt textarea {
  font-size: 12px;
}

.txtfield {
  display: flex !important;
  width: 100%;
  margin-bottom: 20px !important;
}

.txtfield > label {
  font-size: 16px;
  font-weight: 500;
}

.txtfield input {
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 8px;
}

.txtfield textarea {
  font-size: 12px;
}

.txtfield > label[data-shrink="false"] {
  font-size: 12px;
}

.dataRGrid td > big {
  padding: 15px 0px;
}

.dataRGrid {
  margin-top: 10px;
  padding: 0px 5px;
}

.dataRGrid table {
  width: 100%;
  min-width: 100% !important;
}

.dataRGrid table > thead {
  background-color: #f0f0f3;
}

.dataRGrid table thead > tr > th {
  padding: 0px 3px !important;
}

.dataRGrid table thead > tr,
.dataRGrid table tbody > tr {
  height: auto;
}

.dataRGrid table tbody > tr > td {
  font-size: 12px;
  padding: 1px 4px;
}

.dataRGrid table tbody > tr > td:first-child {
  padding-left: 3px;
}

.dataRGrid thead th span {
  color: #757575;
  font-size: 12px;
}

.dataRGrid thead th span > span {
  min-width: 100%;
}

.dataRGrid > div > div {
  overflow-x: hidden;
}

label.form-label {
  font-size: 12px;
  font-weight: 500;
}

.dropdown > label {
  background: white;
  padding: 0px 5px;
  font-weight: 500;
}

.dropdown > label[data-shrink="false"] {
  font-size: 12px;
  font-weight: 500;
}

.dropdown select {
  font-size: 12px;
  padding-top: 8px;
  height: 20px;
  padding-bottom: 4px;
}

.editVehicleWrapper .col {
  width: 32%;
}

.editVehicleTab {
  min-height: auto !important;
  margin-bottom: 25px;
  margin-top: -15px;
}

.editVehicleTabIndicator {
  background: #800000 !important;
}

div#iconsList {
  position: absolute;
  box-shadow: 1px 2px 5px #8a8a8a;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 12px;
  width: 80%;
  background: white;
  margin-bottom: 15px;
}

div#iconsList > ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  flex-wrap: wrap;
}

div#iconsList > ul > li > img {
  cursor: pointer;
}

div#sensorsGrid {
  overflow-x: hidden;
  margin-top: -10px;
}

div#sensorsGrid > ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

div#sensorsGrid > ul > li:first-child {
  position: relative;
  margin-right: 10px;
}

div#sensorsGrid ul > li > button {
  padding: 6px !important;
  top: 0px;
}

div#sensorsGrid ul > li svg {
  font-size: 16px;
}

input#sensorSearch {
  margin-top: 0px !important;
}

.dialogBox.editSensorWrapper {
  right: 0 !important;
  left: 0 !important;
  width: 50%;
  margin: auto;
}

.dialogBox.editSensorWrapper .col {
  width: 47%;
}

.dialogBox.editSensorWrapper .col .mB10 {
  margin-bottom: 10px !important;
}

div[role="dialog"] {
  z-index: 999999999999 !important;
}

.font-12 {
  font-size: 12px !important;
}

.profileSettingsWrapper .dialogBtn {
  display: none;
}

button.settingsBtn {
  margin-bottom: 10px;
  margin-top: 10px;
}

.settingsTabs .scrollButtons {
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
}

div#myAccount {
  width: 75%;
}

.settingsGrid > ul {
  display: flex;
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

.settingsGrid > ul svg {
  font-size: 16px;
}

.settingsGrid .searchDiv {
  position: relative;
  margin-top: -5px;
  margin-right: 10px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.fleetTooltip {
  z-index: 9999999999 !important;
}

div[role="tooltip"] {
  z-index: 99999999;
}

input#assignedVehicles {
  font-size: 12px;
  font-weight: 500;
  padding-top: 8px;
}

.autocomplete-box[aria-expanded="false"] label {
  font-size: 16px;
}

div#videoCallPopup {
  left: 0 !important;
  right: 0 !important;
  top: -200px !important;
}

div#videoCallPopup .dialogBody {
  min-height: auto;
  width: 400px;
}

li#myProfileBtn::after {
  background: silver;
  width: 6px;
  height: 6px;
  content: "";
  position: absolute;
  right: 12px;
  border-radius: 10px;
  top: 10px;
}

li#myProfileBtn.online::after {
  background: green;
}

li#myProfileBtn.offline::after {
  background: red;
}

.dialogBox.dateTimeWrapper,
.dialogBox.broadcastWrapper {
  width: 100%;
}

.MuiSnackbar-root {
  z-index: 999999999999 !important;
}

.primaryLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

div#root.scroll {
  overflow-y: auto;
}

a.hyperlink {
  text-decoration: none;
}

.zonesTab.usersTab ul {
  display: flex;
  list-style: none;
  padding: 0 5px;
  margin: 0;
}

.zonesTab.usersTab ul li.poiBtn button {
  padding: 5px;
}

.zonesTab.usersTab ul li.poiBtn button svg {
  font-size: 18px;
}

.zonesTab.usersTab ul li.poiBtn {
  margin-top: 10px;
  margin-left: 5px;
}

.zonesTab.usersTab ul li:first-child {
  width: 100%;
  position: relative;
}

#incidentSettings ul {
  display: flex;
  list-style: none;
  padding: 0 5px;
  margin: 0;
  margin-top: -8px;
}

#incidentSettings ul li:first-child {
  position: relative;
  width: 250px;
}

div#incidentListGrid {
  margin-top: 4px !important;
}

div#incidentListGrid .options button {
  padding: 4px;
}

div#incidentListGrid .options button svg {
  font-size: 16px;
}

div#fireUsersGrid .options button {
  padding: 5px;
}

.MuiDialog-root.dialogBox.profileSettingsWrapper .MuiDialog-paperScrollPaper {
  max-height: 100% !important;
}
