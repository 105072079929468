div#vehicleList {
    overflow-y: auto;
    overflow-x: visible;
    height: 500px;
}

div#vehicleList .expansionPanelSummary {
    position: fixed;
    width: 344px;
    background-color: #F3F3FF;
    padding: 0px 15px !important;
    min-height: auto !important;
    border-radius: 0px;
    z-index: 999999;
}

div#vehicleList span.expansionPanelTitle {
    padding: 8px 0px;
}

div#vehicleList span.visibilityIcon {
    top: 2px;
}

div#vehicleList .toggleIcon {
    top: 8px;
}

div#vehicleList .expansionPanelDetails {
    background-color: #fff;
}

div#vehicleList .expansionPanelDetails .vehiclerow {
    padding: 0px !important;
    border-bottom: 1px solid #f3f3ff;
}

div#vehicleList .expansionPanelDetails .vehicleicon {
    text-align: center;
    width: 15%;
}

.ReactVirtualized__List {
    outline: none !important;
}

.vehiclerow .vehicledata {
    width: 75%;
}

div#vehicleList .expansionPanelDetails .showvehicleicon>svg {
    width: 14px;
}

div#vehicleList .expansionPanelDetails .vehicleicon img {
    width: 60%;
    padding-left: 2px;
    padding-right: 2px;
}

div#vehicleList .expansionPanelDetails .vehiclerow h4 {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    margin-top: 2px;
}

div#vehicleList .expansionPanelDetails .vehiclerow p.vtime {
    font-size: 10px;
    color: #959595;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 5px;
}

div#vehicleList .expansionPanelDetails .vehiclerow p.vaddress {
    font-size: 11px;
    color: #000;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 1.3;
    text-align: justify;
    text-transform: capitalize;
    min-height: 30px;
}

div#vehicleList .expansionPanelDetails .vehiclerow ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 4px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

div#vehicleList .expansionPanelDetails .vehiclemenu>button {
    padding: 0px;
    margin-right: 0px;
}

div#vehicleList .expansionPanelDetails .vehiclerow ul>li:first-child {
    padding-left: 2px;
}

div#vehicleList .expansionPanelDetails>table {
    width: 100%;
    border-color: transparent;
}

div#vehicleList .expansionPanelDetails .vehiclemenu {
    text-align: center;
    width: 10%;
}

div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus {
    background-color: #909090;
    padding: 3px;
    font-size: 10px;
    border-radius: 3px;
    color: #fff;
    margin-left: 5px;
    font-weight: bold;
    width: 50px !important;
    position: absolute;
    text-align: center;
    margin-top: 4px;
    right: 15px;
}

div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.running {
    background-color: #61A262;
}

div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.nosignal {
    background-color: #C4C4C4;
}

div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.idle {
    background-color: orange;
}

div#vehicleList .expansionPanelDetails .vehiclerow span.vehiclestatus.stopped {
    background-color: red;
}

div#vehicleList .expansionPanelDetails .smallicon {
    width: 16px;
    height: 16px;
}

.reload-vehicles {
    position: absolute;
    right: 24px;
    top: 9px;
}

.reload-vehicles button {
    padding: 6px !important
}

.reload-vehicles svg {
    font-size: 18px;
}

div#vehicleList .expansionPanelDetails .vehiclerow ul>li {
    padding: 0px 5px;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

div#vehicleList .expansionPanelDetails .vehiclerow ul>li span.text {
    padding-left: 2px;
    padding-top: 2px;
}

div#vehicleList .expansionPanelDetails showvehicleicon>button {
    padding: 5px;
}

div#vehicleList .expansionPanelDetails showvehicleicon>button svg {
    font-size: 14px;
}

div#vehicleList span.visibilityIcon>button {
    padding: 5px;
}

div#vehicleList::-webkit-scrollbar {
    width: 8px;
}

div#vehicleList::-webkit-scrollbar-track {
    background: #ccc;
}

div#vehicleList::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
}

div#vehicleList .expansionPanel {
    padding: 0px;
    box-shadow: none !important;
    overflow: hidden;
    border-left: 2px solid #f3f3ff;
    border-bottom: 2px solid #f3f3ff;
    border-right: 2px solid #f3f3ff;
    margin: 0px !important;
    border-radius: 0;
}

div#vehicleList .expansionPanelSummary>div {
    margin: 0 !important;
}

div#vehicleList .expansionPanelDetails {
    padding: 0px 0px !important;
    position: relative;
    border-radius: 0;
}

div#vehicleList .expansionPanelDetails p {
    margin: 0px;
}

div#vehicleList span.expansionPanelTitle {
    width: 310px;
    left: 15px;
    position: relative;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: #808080;
}

span.vehiclegroup {
    background-color: gray;
    padding: 1px 3px;
    font-size: 10px;
    border-radius: 3px;
    color: #fff;
    margin-left: 3px;
}



/* Pop over */
.popover {
    overflow: visible;
    z-index: 99999;
}

span.vehiclename {
    top: 2px;
    position: relative;
    font-size: 12px;
    font-weight: bold;
}

div#vehicleList .vehiclerow {
    cursor: pointer;
}

div#vehicleList .vehiclerow.selected {
    background-color: #dcdcff;
}




#vehicleList .animated-background {
    height: 55px;
}

.no-records-found {
    color: #747474;
    text-align: center;
    height: 25px;
}

.vehiclelist-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #F3F3FF;
    padding: 6px 8px !important;
}

.vehiclelist-title {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: #808080;
}

.hideunhide-vehicle>button {
    padding: 4px;
    margin-right: 5px;
    margin-left: 4px;
}

.hideunhide-vehicle svg {
    font-size: 16px;
}

.vehiclerow {
    display: flex;
    align-items: center;
}