#videocallWrapper {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
}

#pageTitle {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}

div#logo {
    margin: auto;
    height: 20%;
}

div#logo img {
    width: 100px;
    margin: 30px 0px;
}

#videoRooms {
    display: flex;
    padding: 0px;
    position: relative;
    background: #000;
    overflow: hidden;
}

.videoRoom {
    position: relative;
    padding: 0px;
}

.videoRoom video {
    /* transform: rotateY(180deg); */
    width: 100%;
    margin: auto;
    display: block;
    background: #000;
    height: 90%;
}

.videoRoom h4 {
    color: #FFF !important;
    text-align: center;
    margin: 0px 0px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    filter: drop-shadow(1px 1px 2px black);
}

.fullScreen {
    width: 70%;
    margin: auto;
}

.fullScreenBtn {
    position: absolute;
    top: 28px;
    right: 4px;
}

.fullScreenBtn button,
.fullScreenBtn2 button {
    padding: 6px;
}

.fullScreenBtn svg,
.fullScreenBtn2 svg {
    font-size: 18px;
}

.videoRoom.small {
    width: 150px;
    position: absolute;
    z-index: 9999;
}

.videoRoom.large {
    width: 100%;
    height: 100%;
}

.videoRoom.small h4 {
    position: absolute;
    z-index: 99999;
    top: 5px;
    left: 5px;
}

.videoRoom.large h4 {
    position: absolute;
    z-index: 99999;
    right: 8px;
    top: 4px;
}

.videoRoom.large video.rotated90 {
    transform: rotate(90deg) !important;
}

.videoRoom.large video.rotated270 {
    transform: rotate(270deg) !important;
}

.videoRoom.large video.mirror {
    transform: rotateY(180deg);
}

.switchCamBtn svg {
    filter: drop-shadow(1px 1px 2px black);
    color: #FFF;
    font-size: 20px;
}

.switchCamBtn {
    position: absolute;
    right: 50%;
    z-index: 99999;
    bottom: 5px;
    left: 50%;
    display: flex;
    justify-content: space-evenly;
}

.switchCamBtn button,
.actionbtns button {
    padding: 6px;
}

.switchCamBtn button {
    margin-right: 10px;

}

.actionbtns svg {
    color: #FFF;
    font-size: 18px;
    filter: drop-shadow(1px 1px 1px black);
}

.videoRoom.hide {
    display: none !important;
}

.actionbtns {
    position: absolute;
    z-index: 99999;
    bottom: 3px;
    right: 2px;
}

.videoRoom.show {
    width: 100%;
    position: relative;
    height: 100%;
}

.slowlink svg {
    color: red;
    margin-top: 5px;
    font-size: 20px;
    margin-right: 10px;
    margin-left: 2px;
}

div#videoCallPopup .dialogBtn {
    margin: 0;
    padding: 0;
}

.callBtns {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: auto;
}

.callBtns button {
    padding: 2px 20px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
}

button.hangup {
    background: red;
}

button.hangup:hover {
    background: red;
    box-shadow: 1px 2px 5px #e0e0e0;
}

button.answer {
    background: green;
    margin-right: 10px;
}

button.answer:hover {
    background: green;
    box-shadow: 1px 2px 5px #e0e0e0;
}

#videoRoom2.online h4::before {
    background: green;
}

#videoRoom2 h4::before {
    background: silver;
    width: 6px;
    height: 6px;
    content: '';
    position: absolute;
    right: -6px;
    border-radius: 10px;
    top: -2px;
}