.uidatepicker {
    display: flex;
    margin-bottom: 15px;
}

.uidatepicker label {
    font-size: 18px;
    font-weight: 500;
    color: #800000;
}

.uidatepicker input {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 4px;
}

.uidatepicker svg {
    font-size: 18px;
    position: relative;
    top: 0px;
    color: #434343;
}

.datetime-picker {
    position: relative;
}