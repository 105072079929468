.centerBox {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 10%;
}

div#videoRoomTitle h1 {
  font-size: 22px;
  text-align: center;
  color: #800000;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 0;
  padding-bottom: 10px;
}

div#videoRoomBody {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.videoRoomBox {
  width: 33%;
  position: relative;
  border: 1px solid #fff;
  height: 323px;
  background: #000;
  overflow: hidden;
}

.videoRoomBox video {
  width: 100%;
  height: 100%;
  background: #000;
}

.videoroom {
  height: 100%;
  background: #fff;
}

div#roomLink span {
  color: #800000;
  font-size: 14px;
}

div#roomLink label {
  color: #585858;
  font-size: 14px;
}

div#roomLink {
  padding: 5px 5px;
  text-align: center;
}

div#videoRoomFilter button {
  background: #e4a403;
  color: #fff;
  padding: 4px 0px;
  font-size: 10px;
  font-weight: bold;
  width: 80px;
}

.videoRoomBox p {
  position: absolute;
  bottom: 9px;
  color: #fff;
  left: 7px;
  margin: 0;
  font-weight: 500;
  text-shadow: 1px 2px 5px #000;
  font-size: 12px;
}

.videoRoomBox button.microphoneBtn {
  position: absolute;
  bottom: 3px;
  right: 0;
  padding: 5px;
}

.videoRoomBox button.videoBtn {
  position: absolute;
  right: 25px;
  bottom: 3px;
  padding: 5px;
}

.videoRoomBox button.slowNetBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}

.videoRoomBox button svg {
  font-size: 18px;
  color: #fff;
  filter: drop-shadow(0px 1px 1px black);
}

.videoRoomBox button.slowNetBtn svg {
  color: red;
}

span#bitrate {
  position: absolute;
  top: 3px;
  left: 3px;
  color: #fff;
  text-shadow: 1px 2px 5px #000;
  font-size: 12px;
  font-weight: 500;
}

div#videoRoomFilter button.stopBtn {
  background: red !important;
  opacity: 1;
}

div#videoRoomFilter button.stopBtn.disabled {
  opacity: 0.4;
}

div#videoRoomFilter button.joinBtn {
  background: green !important;
}

div#videoRoomFilter button.joinBtn.disabled {
  opacity: 0.5;
}

div#videoRoomFilter {
  display: flex;
  justify-content: space-around;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0px 15px;
  padding-bottom: 12px;
}

div#videoRoomFilter button {
  margin-right: 10px;
}

div#videoRoomFilter button:last-child {
  margin-right: 0;
}

div#topRightBox {
  position: absolute;
  bottom: 0;
  padding-top: 0px !important;
  padding-bottom: 4px !important;
  height: auto !important;
  padding-left: 10px !important;
}

div#reportLogo img {
  border: none !important;
}

div#videoRoomTitle {
  position: relative;
}

div#reportUserInfo h4 {
  color: #800000 !important;
  margin-top: 0 !important;
}

div#reportLogo {
  height: 30px;
  width: 30px;
}

.videoRoomBox button.microphoneBtn.disabled svg {
  color: red;
}

.videoRoomBox button.videoBtn.disabled svg {
  color: red;
}

#activeRooms {
  width: 100%;
  padding: 0px 15px;
  font-size: 14px;
}

div#activeRooms p {
  color: #800000;
  font-weight: 500;
}

div#activeRooms a {
  text-decoration: none;
}

div#activeRooms tr:hover {
  background-color: whitesmoke;
  font-weight: 500;
}

.videoRoomBox video.rotated90 {
  transform: rotate(90deg) !important;
}

.videoRoomBox video.rotated180 {
  transform: rotateY(180deg) !important;
}

.videoRoomBox video.rotated270 {
  transform: rotate(270deg) !important;
}

.videoRoomBox video.mirror {
  transform: rotateY(180deg);
}

.vcOptions {
  position: absolute;
  bottom: 0;
  left: 42%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

button.vcCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}

button.vcCloseBtn svg {
  color: red;
}
