.SearchInput {
    right: 1px;
    top: 12px;
    width: 100% !important;
    font-size: 12px !important;
    margin-top: 10px !important;
    border: 1px solid #DFDBDB !important;
    border-radius: 5px;
    padding: 3px 5px !important;
    min-height: 20px;
}

input.SearchInput:focus {
    border-color: #800000 !important;
}

.searchBtn {
    position: absolute !important;
    right: 0;
    padding: 6px !important;
    top: 10px;
}